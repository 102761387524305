<template>
    <div class="tab-area">
        <div class="row page-title">
        <label class="Agency-Name-HFM-Co">
          {{ Request.VendorBusinessName }} </label>
        </div>
        <div class="page-details row">
          <b-alert :show="IsDataLoaded && !IsApproveOrRejectEnabled" class="alert-info-new position-fixed fixed-top m-0 rounded-0" variant="info" dismissible>
              The Request is already approved or rejected.
          </b-alert>
            <prodexp-amend-overview class="col-lg-8" v-on:LoadProdEXPAmendDetails="LoadVendorRequestDetails" :Request="Request" :ID="id"></prodexp-amend-overview>
            <vendor-validation-details class="col-lg-4"  v-on:LoadProdEXPAmendDetails="LoadVendorRequestDetails" :Request="Request" :VendorFormType="vendorType" :ID="id"></vendor-validation-details>
        </div>
    </div>
</template>

<script>
import * as status from "../../../utilities/VendorWorkflowStates";

export default {
    name: "VendorValidation",
    data() {
        return {
            vendorType:"Amend Production Expense",
            Request: {},
        };
    },
    props: ["id"],
    mounted() {
        if (!this.canApprovePendingVendorValidation) {
            this.$router.push({
                name: "error",
                params: { message: "UnAuthorized" },
            });
        }
    },
    computed: {
        IsApproveOrRejectEnabled() {
            return this.Request && (this.Request.Status == status.VendorValidation || this.Request.Status == status.VendorValidationRejected);
        },
        IsDataLoaded() {
            return this.Request && this.Request.VendorBusinessName ? true : false;
        },
        canApprovePendingVendorValidation() {
            return this.$store.getters.canApprovePendingVendorValidation;
        }
    },
    methods: {
        LoadVendorRequestDetails(vendorRequestDetails){
            this.Request = vendorRequestDetails;
        },
    }
};

</script>
